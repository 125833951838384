body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: rgb(245, 238, 141);
}

.App {
  text-align: center;
  font-family: "Lato", helvetica, arial, sans-serif;
}

.verdictLine {
  margin: 2%;
  font-size: 2em;
  font-weight: bold;
}

.avgSentimentLine {
  font-size: 0.3em;
  font-weight: normal;
}

.headingBox {
  font-family: "Lato", helvetica, arial, sans-serif;
  font-weight: bold;
  font-size: 2em;
  margin: 1%;
  padding: 1%;
}

.largeSmileyFace {
  width: 100%;
  height: auto;
}

.userImagesBlock {
  height: 140px;
  width: 30%;
  margin-left: auto;
  margin-right: auto;
}

.smileyContainer {
  float: right;
}

.avatarStyling {
  height: 120px;
  float: left;
  width: auto;
  margin: 10px;
}

.centeredAvatar {
  height: 120px;
  width: auto;
  margin: 10px;
}

/* comment results lines */

.allCommentsContainer {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 140px;
}

.commentLine {
  width: 100%;
  min-height: 3em;
  margin-top: 1em;
  border-top: 1px black dotted;
  padding: 3px;
  margin-bottom: 1.8em;
}

.commentText {
  width: 70%;
  font-family: "Lato", helvetica, arial, sans-serif;
  font-size: 0.8em;
  text-align: left;
  display: inline-flex;
  overflow: hidden;
}

.commentDateLine {
  width: 100%;
  font-size: 0.8em;
  text-align: center;
  font-weight: bold;
}

.smileyBox {
  float: right;
  display: inline-flex;
  margin-top: -10px;
  height: 100%;
  width: 20%;
  padding: 0%;
  font-size: 0.4em;
  /*border:1px red solid;*/
}
.sentimentLabel {
  width: 100%;
  height: 10%;
  text-align: center;
}

.smallImage {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 600px) {
  .userImagesBlock {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
  }

  .avatarStyling {
    height: 80%;
    float: unset;
    width: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .centeredAvatar {
    height: 80%;
    width: auto;
    margin: 10px;
  }

  .smileyBox {
    float: unset;
  }

  .verdictLine {
    height: 2em;
    width: 100%;
  }
  .allCommentsContainer {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 140px;
  }
  .smileyContainer {
    float: unset;
  }
  .sentimentLabel {
    width: 100%;
    height: 10%;
    text-align: center;
    font-size: 2em;
  }
  .avatarStyling {
    height: 120px;
    float: unset;
    width: auto;
    margin: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

